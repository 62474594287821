import { Fragment } from "react";
import buildingIcon from "../assets/icons/building.svg";
import elevatorIcon from "../assets/icons/elevator.svg";
import calendarIcon from "../assets/icons/calendar-clock.svg";

const ElevatorInfo = ({id}) => {
  return (
    <Fragment>
      <div className="text-sm">Call from:</div>
      <div className="flex flex-1 mt-2 gap-2">
        <div>
          <img src={buildingIcon} />
        </div>
        <div>
          <div>London, 14 Springfield Road</div>
        </div>
      </div>
      <div className="flex flex-1 mt-2 gap-2">
        <div>
          <img src={elevatorIcon} />
        </div>
        <div>
          <div>Elevator no {id}</div>
        </div>
      </div>
      <div className="flex flex-1 mt-2 gap-2">
        <div>
          <img src={calendarIcon} />
        </div>
        <div>
          <div>03/05/2024, 08:07</div>
        </div>
      </div>
    </Fragment>
  );
};

export default ElevatorInfo;
