import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";

// MSAL imports
import { EventType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
  useMsal,
} from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import Home from "./pages/Home";
import Elevator from "./pages/Elevator";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { SIPProvider } from "./context/SIPContext";
import IncomingCallNotification from "./components/IncomingCallNotification";
import { b2cPolicies, loginRequest } from "./authConfig";
import { callApi } from "./utils/CallApi";
import LoginRedirect from "./pages/Login";

function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function Layout({user}) {
  const { instance } = useMsal();
  const [status, setStatus] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sign-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }

      if (
        event.eventType === EventType.SSO_SILENT_SUCCESS &&
        event.payload.account
      ) {
        setStatus("ssoSilent success");
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <SIPProvider>
      <Navbar />
      <div className="flex flex-1">
        <Sidebar id={id} />
        <div className="flex-1 p-4">
          <div className="md:pl-14 lg:pl-52 pt-16">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/call/:id" element={<Elevator />} />
            </Routes>
          </div>
        </div>
      </div>
      <IncomingCallNotification user={user} />
    </SIPProvider>
  );
}

function AppContent() {
  const [user, setUser] = useState(3)
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    if (!instance.getActiveAccount()) {
      instance.loginRedirect();
    }
  }, [instance]);

  useEffect(() => {
    if (activeAccount) getToken();
  }, [activeAccount]);

  const getToken = async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      console.log("Access Token: ", tokenResponse.accessToken);
      getSipInfo(tokenResponse.accessToken);
    } catch (err) {
      console.log("getToken", err);
    }
  };

  const getSipInfo = async (accessToken) => {
    const url =
      "https://dev-api.dewhurst.integration-team.pl/UserInfo/sip-identity";
    callApi(accessToken, url)
      .then((res) => {
        console.log("sip", res);
        setUser(res.login)
      })
      .catch((err) => console.log("sip err", err));
  };

  return (
    <Fragment>
      <AuthenticatedTemplate>
        <div className="flex flex-col">
          <Routes>
            <Route path="/*" element={<Layout user={user} />} />
          </Routes>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginRedirect />
      </UnauthenticatedTemplate>
    </Fragment>
  );
}

function App({ pca }) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <AppContent />
      </MsalProvider>
    </ClientSideNavigation>
  );
}

export default App;
