import React, { Fragment, useContext, useEffect } from "react";
import { SIPContext } from "../context/SIPContext";
import {
  Dialog,
  DialogPanel,
  Transition
} from "@headlessui/react";
import Button from "./Button";
import WarningIcon from "../assets/icons/warning.svg";
import BuildingIcon from "../assets/icons/building.svg";
import ElevatorIcon from "../assets/icons/elevator.svg";
import CalendarClockIcon from "../assets/icons/calendar-clock.svg";
import ImagePlaceholder from '../assets/icons/image_placeholder.svg';
import useDisclosure from "../hooks/useDisclosure";
import { useNavigate } from "react-router-dom";

const IncomingCallNotification = ({user}) => {
  const navigate = useNavigate();
  const { incomingSession, incomingCallNumber } = useContext(SIPContext);
  const {isOpen, open, toggle} = useDisclosure()

  useEffect(()=>{
    if(incomingSession) open()
  },[incomingSession])

  const close = () => {
    toggle()
  }
  const accept = () => {
    const currentUrl = window.location.href;
    const query = currentUrl.includes('call') ? '' : '?withRefresh=true';
    navigate(`/call/${incomingCallNumber}${query}`)
    close()
  }

  return (
    incomingSession && (
      <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
      onClose={()=>{}}
      className="relative z-10"
      static
    >
      <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" >
      <div className="fixed top-20 right-4 z-10 w-auto">
        <div className="flex min-h-full items-start justify-end text-center sm:items-start sm:justify-end sm:p-0">
          <DialogPanel
            transition
            className="relative w-96 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="popupAlert divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow border border-red-500">
              <div className="popupAlert__header px-4 py-5 sm:px-6 bg-red-500 text-white">
                <div className="flex gap-4 justify-center">
                  <img src={WarningIcon} />
                  <span>Alert Call</span>
                </div>
              </div>
              <div className="popupAlert__body px-4 py-5">
                <div className="flex gap-4">
                  <div className="flex-1 text-sm">
                    <div>Call from</div>
                    <div className="flex mt-2 gap-2">
                      <div>
                        <img src={BuildingIcon} />
                      </div>
                      <div>
                        <div>London,</div>
                        <div>14 Springfield Road</div>
                      </div>
                    </div>
                    <div className="flex gap-2 mt-2">
                      <div>
                        <img src={ElevatorIcon} />
                      </div>
                      <div>Elevator no 2</div>
                    </div>
                    <div className="flex gap-2 mt-2">
                      <div>
                        <img src={CalendarClockIcon} />
                      </div>
                      <div>03/05/2024, 08:07</div>
                    </div>
                  </div>
                  <div className="flex-1 text-sm">
                    <div>Elevator Screenshot</div>
                    <div className="mt-2">
                      <img src={ImagePlaceholder} />
                    </div>
                  </div>
                </div>
                <Button className='w-full mt-7' variant={'default'} name={'Accept'} onClick={accept} />
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
      </Transition.Child>
    </Dialog>
    </Transition.Root>
    )
  );
};

export default IncomingCallNotification;
