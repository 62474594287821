import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const WelcomeName = () => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount && activeAccount.name) {
            setName(activeAccount.name.split(' ')[0]);
        } else if(activeAccount && activeAccount.idTokenClaims) {
            let {given_name, family_name} = activeAccount.idTokenClaims;
            setName(`${given_name} ${family_name}`)
        } else if (activeAccount && activeAccount.username){
            setName(activeAccount.username)
        }else {
            setName(null);
        }
    }, [activeAccount]);

    if (name) {
        return <h6>Welcome, {name}</h6>;
    } else {
        return null;
    }
};

export default WelcomeName;