const ConversationItem = ({ type, text }) => {
    const class1 =
      type.toLowerCase() !== "operator" ? "flex justify-end flex-col items-end" : "";
    const class2 = type.toLowerCase() !== "operator" ? "text-indigo-700" : "text-blue-800";
    return (
      <div className={`w-full ${class1}`}>
        <div className={`text-xs font-semibold capitalize ${class2}`}>{type}</div>
        <div className="text-sm">{text}</div>
      </div>
    );
  };

export default ConversationItem