import React, { createContext, useState, useEffect, useContext } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const MessagesContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [connection, setConnection] = useState(null);
  const [currentTopic, setCurrentTopic] = useState('');

  useEffect(() => {
    const connect = async () => {
      const hubConnection = new HubConnectionBuilder()
        .withUrl('https://dev-api.dewhurst.integration-team.pl/messaging', { withCredentials: false }).build();
        //.configureLogging(LogLevel.Information)
       
      hubConnection.on('ReceiveMessage', (topic, user, message) => {
        setMessages((prevMessages) => [...prevMessages, { type: user, text: message }]);
      });
      try {
        await hubConnection.start();
        console.log('SignalR Connected.');
      } catch (error) {
        console.error('SignalR Connection Error: ', error);
      }
      setConnection(hubConnection);
    };
    connect();
    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);


  const joinTopic = async (topic) => {
    if (connection) {
      try {
        await connection.invoke('JoinToDeviceTopic', topic);
        setCurrentTopic(topic);
      } catch (error) {
        console.error('Join topic error: ', error);
      }
    }
  };

  const leaveTopic = async () => {
    if (connection && currentTopic) {
      try {
        await connection.invoke('LeaveDeviceTopic', currentTopic);
        setCurrentTopic('');
      } catch (error) {
        console.error('Leave topic error: ', error);
      }
    }
  };

  const addMessage = async (device, message) => {
    const type = 'operator'
    const text = message;
    if (connection) {
      try {
        await connection.invoke('SendMessage', currentTopic, device, message);
      } catch (error) {
        console.error('Send message error: ', error);
      }
    }
    //setMessages((prevMessages) => [...prevMessages, { type, text }]);
  };

  return (
    <MessagesContext.Provider value={{ messages, joinTopic, leaveTopic, addMessage, connection, currentTopic }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => {
  return useContext(MessagesContext);
};