import buildingIcon from "../assets/icons/building_white.svg";
export default function Sidebar() {
  return (
    <div
    className="sidebar hidden md:fixed md:inset-y-0 md:z-50 md:flex md:flex-col md:w-14 lg:w-52 bg-dark-blue text-white h-[calc(100%-64px)] mt-16"
    style={{ backgroundColor: "rgba(0, 48, 140, 1)" }}
  >
      <div className="flex grow flex-col gap-y-5 overflow-hidden pl-4 lg:pl-6 pb-4">
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7 pt-4">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                <li className="rounded-md isActive">
                  <a
                    href="#"
                    className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                  >
                    <img src={buildingIcon} />
                    <span className="hidden lg:inline">ECS</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
