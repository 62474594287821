import { useEffect } from "react"
import {
    useMsal,
  } from "@azure/msal-react";

export default function LoginRedirect()
{
    const { instance } = useMsal();
    useEffect(()=>{
        if(instance) instance.loginRedirect();
    },[instance])
    return <></>
}