import { Fragment, useEffect, useState } from "react";
import logo from "../assets/icons/logo.svg";
import logo_mobile from "../assets/icons/logo_mobile.svg";
import notifications from "../assets/icons/notifications.svg";
import account from "../assets/icons/account_circle.svg";
import { useMsal } from "@azure/msal-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

export default function Navbar() {
  const { instance } = useMsal();
  const [name, setName] = useState("Adrian Ciepły");
  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    if (activeAccount && activeAccount.name) {
      setName(activeAccount.name.split(" ")[0]);
    } else if (activeAccount && activeAccount.idTokenClaims) {
      let { given_name, family_name } = activeAccount.idTokenClaims;
      setName(`${given_name} ${family_name}`);
    } else if (activeAccount && activeAccount.username) {
      setName(activeAccount.username);
    } else {
      setName(null);
    }
  }, [activeAccount]);
  const logout = () => {
    instance.logoutRedirect();
  };
  return (
    <Fragment>
      <nav className="w-full border-b border-gray-200 bg-white fixed z-10">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="block h-8 w-auto lg:hidden"
                  src={logo_mobile}
                  alt="Dupral"
                />
                <img
                  className="hidden h-8 w-auto lg:block"
                  src={logo}
                  alt="Dupral"
                />
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <button
                type="button"
                className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">View notifications</span>
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src={notifications}
                  alt=""
                />
              </button>
              <div className="relative ml-4">
                <div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton className="items-center relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5"></span>
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={account}
                          alt=""
                        />
                        <span className="hidden lg:flex lg:items-center">
                          <span
                            className="ml-2 text-sm font-semibold leading-6 text-gray-900"
                            aria-hidden="true"
                          >
                            {name}
                          </span>
                          <svg
                            className="ml-2 h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <div
                          onClick={logout}
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                        >
                          Sign out
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}
