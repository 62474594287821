import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import Button from "./Button";
import { questionsList } from "../data/questionsList";
import { useMessages } from "../context/MessagesContext";
import sendIcon from "../assets/icons/send.svg"

export default function DialogQuestions({ open, close }) {
    const { addMessage } = useMessages();

    const sendMessage = (questionText) => {
      addMessage("operator", questionText);
      close()
    };
  return (
    <Dialog open={open} onClose={close} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <div className="font-semibold">Send question to passanger</div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4 pb-4">
                    {questionsList.map((question, index) => (
                      <Button 
                        key={index} 
                        name={question.text} 
                        iconLeft={question.icon}
                        iconRight={sendIcon}
                        className="w-80 py-4" 
                        onClick={() => sendMessage(question.text)}/>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
