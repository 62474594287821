import hearIcon from '../assets/icons/hear.svg'
import elevatorDoorCloseIcon from '../assets/icons/elevator-door-close.svg'
import policeIcon from '../assets/icons/police.svg'
import powerIcon from '../assets/icons/power.svg'
import medicalIcon from '../assets/icons/medical.svg'
import helpIcon from '../assets/icons/help.svg'

export const questionsList = [
    {"text": "Can you hear me?", icon: hearIcon},
    {"text": "Is the elevator door stuck?", icon: elevatorDoorCloseIcon},
    {"text": "Do you need the Police?", icon: policeIcon},
    {"text": "Has the power failed?", icon: powerIcon},
    {"text": "Do you need medical help?", icon: medicalIcon},
    {"text": "Help is on the way", icon: helpIcon}
]