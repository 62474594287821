import React from "react";

export default function Button({
  name,
  variant,
  className,
  onClick,
  iconLeft,
  iconRight,
  isDisabled = false
}) {
  let defaultClass = "py-2 px-4 rounded focus:outline-none font-semibold flex";
  let buttonClasses = defaultClass;

  switch (variant) {
    case "outline":
      buttonClasses += " bg-white text-sky-700 border border-sky-700";
      break;
    case "danger":
      buttonClasses += " bg-red-500 text-white border border-red-500";
      break;
    default:
      buttonClasses += " bg-sky-600 text-white border border-sky-600";
      break;
  }

  if (className && !isDisabled) buttonClasses += ` ${className}`;
  if (isDisabled) buttonClasses = defaultClass += ' bg-gray-500 text-white border border-gray-500'

  return (
    <button className={buttonClasses} onClick={onClick} disabled={isDisabled}>
      <div className="flex-1">
        {iconLeft ? <img src={iconLeft} alt="icon" /> : ""}
      </div>
      <div className="flex-auto">{name}</div>
      <div className="flex-1 flex justify-end">
        {iconRight ? <img src={iconRight} alt="icon" /> : ""}
      </div>
    </button>
  );
}
