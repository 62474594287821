import { Fragment, useContext, useEffect, useState } from "react";
import Button from "./Button";
import { SIPContext } from "../context/SIPContext";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Input,
} from "@headlessui/react";
import useDisclosure from "../hooks/useDisclosure";

export default function HoldCall({ isDesktop }) {
  const [number, setNumber] = useState("");
  const { isOpen, open, toggle } = useDisclosure();
  const { holdCall, unholdCall, isOnHold, makeCall } = useContext(SIPContext);
  const className = isDesktop
    ? "w-full mt-4 py-4 hidden lg:block"
    : "w-full mt-4 py-4 block lg:hidden";

  const toggleHold = () => {
    if (isOnHold) unholdCall();
    else open();
  };

  const callToNumber = async() => {
    await holdCall()
    await makeCall(number)
    toggle()
  }

  useEffect(() => {
    if (!isOpen) setNumber("");
  }, [isOpen]);

  return (
    <Fragment>
      <Button
        onClick={toggleHold}
        name={isOnHold ? "Resume Call" : "Hold Call"}
        variant={"outline"}
        className={className}
      />
      <Dialog open={isOpen} onClose={toggle} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Enter number
                  </DialogTitle>
                  <div className="mt-2">
                    <Input
                      value={number}
                      placeholder="XXX-XXX-XXX"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <p className="text-sm leading-6 text-gray-600">Must consist of 9 digits</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <Button
                  name={"Cancel"}
                  onClick={toggle}
                  variant={"outline"}
                />
                <Button name={"Call"} onClick={callToNumber} isDisabled={number.length !== 9} />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
