import { questionsList } from "../data/questionsList";
import { useMessages } from "../context/MessagesContext";
import Button from "./Button";
import sendIcon from "../assets/icons/send.svg"

export default function Questions() {
  const { addMessage } = useMessages();

  const sendMessage = (questionText) => {
    addMessage("operator", questionText);
  };

  return (
    <div className="mt-4">
      <label className="text-sm">Send question to passangers</label>
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6 w-full md:p-7 lg:p-0 relative">
        {questionsList.map((question, index) => (
          <Button
            key={index}
            name={question.text}
            iconLeft={question.icon}
            iconRight={sendIcon}
            className="py-4"
            onClick={() => sendMessage(question.text)}
          />
        ))}
      </div>
    </div>
  );
}
