import { Fragment, useContext } from "react";
import { SIPContext } from "../context/SIPContext";
import { useMessages } from "../context/MessagesContext";
import phoneIcon from "../assets/icons/phone-disabled.svg";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import useDisclosure from "../hooks/useDisclosure";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function EndCallButton({ isDesktop = true }) {
  const navigate = useNavigate();
  const { hangupCall } = useContext(SIPContext);
  const { addMessage } = useMessages();
  const { isOpen, open, toggle } = useDisclosure();
  const endCall = () => {
    hangupCall();
    addMessage("end", "Call terminated");
    navigate(`/`);
  };

  return (
    <Fragment>
      <Button
        name={
          <div className="flex flex-row font-bold text-center justify-center">
            <img src={phoneIcon} className="mr-4" />
            <div>End Call</div>
          </div>
        }
        variant={"danger"}
        className={`w-full mt-4 py-4 ${
          isDesktop ? "hidden lg:flex" : "lg:hidden"
        }`}
        onClick={open}
      />
      <Dialog open={isOpen} onClose={toggle} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    End call?
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to end the alert call?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <Button name={'Stay on call'} onClick={toggle} variant={'outline'}></Button>
                <Button name={'End call'} onClick={endCall}></Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
