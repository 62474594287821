import React, { createContext, useState, useEffect } from "react";
import { UserAgent, Inviter, Registerer, URI, SessionState } from "sip.js";
import JsSIP from "jssip";
export const SIPContext = createContext();

export const SIPProvider = ({ children }) => {
  const [incomingCallNumber, setIncomingCallNumber] = useState(1);
  const [userAgent, setUserAgent] = useState(null);
  const [incomingSession, setIncomingSession] = useState(null);
  const [outgoingSession, setOutgoingSession] = useState(null);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const ipAsterisk = "voip.aden.pl";
  const user = "2727";
  const password = "adenplus12!";
  useEffect(() => {
    const socket = new JsSIP.WebSocketInterface(`wss://${ipAsterisk}:8089/ws`);
    const userAgentOptions = {
      sockets: [socket],
      uri: new URI("sip", user, ipAsterisk),
      authorizationUser: user,
      transportOptions: {
        wsServers: ["wss://" + ipAsterisk + ":8089/ws"],
        traceSip: true,
      },
      authorizationPassword: password,
      displayName: "INT 1",
      register: true,
      register_expires: 600,
      session_timers: true,
      session_timers_refresh_method: "UPDATE",
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: true,
          video: false,
        },
        peerConnectionOptions: {
          rtcConfiguration: {
            iceServers: [
              {
                urls: "stun:stun2.l.google.com:19302",
              },
              {
                urls: "turn:global.relay.metered.ca:443",
                username: "92ac94edbab046c653c7e4d4",
                credential: "CwIV3ioOoh5rKh50",
              },
            ],
            DtlsSrtpKeyAgreement: true,
          },
          sdpSemantics: "unified-plan",
        },
      },
    };

    const ua = new UserAgent(userAgentOptions);

    ua.start()
      .then(() => {
        ua.delegate = {
          onInvite: (invitation) => {
            console.log(
              "invitation from",
              invitation.request.from.uri.normal.user
            );
            setIncomingCallNumber(invitation.request.from.uri.normal.user);
            setIncomingSession(invitation);
          },
          onMessage: (message) => {
            console.log("Message received:", message.body);
          },
        };
        const registerer = new Registerer(ua, userAgentOptions);
        registerer.register();
      })
      .catch((error) => {
        console.error("Failed to start UserAgent:", error);
      });

    setUserAgent(ua);

    return () => {
      if (ua) {
        ua.stop().catch((error) => {
          console.error("Failed to stop UserAgent:", error);
        });
      }
    };
  }, []);

  const answerCall = () => {
    console.log("Answering the call...");
    if (incomingSession) {
      const remoteVideo = document.getElementById("remoteVideo");
      const localVideo = document.getElementById("localVideo");
      incomingSession.accept().then(() => {
        const pc = incomingSession.sessionDescriptionHandler.peerConnection;
        console.log("incomingSession pc", pc);
        const remoteStream = new MediaStream();
        pc.getReceivers().forEach((receiver) => {
          remoteStream.addTrack(receiver.track);
        });

        remoteVideo.srcObject = remoteStream;

        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((stream) => {
            const localStream = new MediaStream();
            stream.getTracks().forEach((track) => {
              pc.addTrack(track, stream);
              localStream.addTrack(track);
            });

            setTimeout(() => {
              remoteVideo.play().catch((error) => {
                console.error("Error playing remote video:", error);
              });
            }, 250);
          });

        setTimeout(() => {
          remoteVideo.play().catch((error) => {
            console.error("Error playing remote video:", error);
          });
        }, 250);
      });
    }
  };

  const makeCall = async (number) => {
    if (!userAgent) {
      console.error("UserAgent is not initialized.");
      return;
    }
    setIsConnecting(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      console.log("Microphone permission granted");
      const target = UserAgent.makeURI(`sip:${number}@${ipAsterisk}`);
      if (!target) {
        console.error("Invalid target URI");
        setIsConnecting(false);
        return;
      }
      console.log(`Attempting to call: sip:${number}@${ipAsterisk}`);
  
      const inviter = new Inviter(userAgent, target, {
        sessionDescriptionHandlerOptions: {
          constraints: { audio: true, video: false },
        },
      });
  
      inviter.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            console.log('Session is establishing');
            break;
          case SessionState.Established:
            console.log('Session has been established');
  
            if (!inviter.sessionDescriptionHandler) {
              console.error("No session description handler found on inviter.");
              return;
            }
  
            const pc = inviter.sessionDescriptionHandler.peerConnection;
            const remoteStream = new MediaStream();
            pc.getReceivers().forEach((receiver) => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track);
                console.log("Track added:", receiver.track);
              }
            });
  
            const remoteAudio = document.getElementById("remoteAudio");
            remoteAudio.srcObject = remoteStream;
  
            setTimeout(() => {
              remoteAudio.play().catch((error) => {
                console.error("Error playing remote audio:", error);
              });
            }, 250);
            break;
          case SessionState.Terminated:
            console.log('Session has terminated');
            break;
          default:
            break;
        }
      });
  
      const newSession = await inviter.invite();
      console.log("Outgoing call initiated to:", number);
      setOutgoingSession(newSession);
    } catch (error) {
      console.error("Error making outgoing call:", error);
    } finally {
      setIsConnecting(false);
    }
  };
  

  const holdCall = async () => {
    console.log("Hold call");
    console.log("incomingSession", incomingSession);
    if (incomingSession) {
      try {
        const pc = incomingSession.sessionDescriptionHandler.peerConnection;
        console.log('incomingSession pc',pc)
        if (pc) {
          const localStream = pc.getLocalStreams()[0];
          if (localStream) {
            localStream.getTracks().forEach((track) => {
              if (track.kind === "audio") {
                track.enabled = false;
              }
            });
            console.log("Incoming call is on hold");
            setIsOnHold(true);
          } else {
            console.error("No local stream found");
          }
        } else {
          console.error("Peer connection is undefined");
        }
      } catch (error) {
        console.error("Failed to put incoming call on hold:", error);
      }
    }
  };

  const unholdCall = async () => {
    console.log("Unhold call");
    console.log("outgoingSession", outgoingSession);
    if (outgoingSession) {
      try {
        // End the outgoing session first
        await outgoingSession.bye();
        console.log("Outgoing call ended");
        setOutgoingSession(null); // Clear the outgoing session
      } catch (error) {
        console.error("Error ending outgoing call:", error);
      }
    }
    console.log("incomingSession", incomingSession);
    if (incomingSession) {
      try {
        const pc = incomingSession.sessionDescriptionHandler.peerConnection;
        if (pc) {
          const localStream = pc.getLocalStreams()[0];
          if (localStream) {
            localStream.getTracks().forEach((track) => {
              if (track.kind === "audio") {
                track.enabled = true;
              }
            });
            console.log("Incoming call resumed from hold");
            setIsOnHold(false);
          } else {
            console.error("No local stream found");
          }
        } else {
          console.error("Peer connection is undefined");
        }
      } catch (error) {
        console.error("Failed to resume incoming call from hold:", error);
      }
    }
  };

  const hangupCall = () => {
    if (incomingSession) {
      incomingSession.bye();
      setIncomingSession(null);
    }
  };

  return (
    <SIPContext.Provider
      value={{
        userAgent,
        incomingSession,
        outgoingSession,
        answerCall,
        makeCall,
        holdCall,
        unholdCall,
        hangupCall,
        incomingCallNumber,
        isOnHold,
        isConnecting,
      }}
    >
      {children}
    </SIPContext.Provider>
  );
};
