import { Fragment, useEffect, useRef } from "react";
import { useMessages } from "../context/MessagesContext";
import ConversationItem from "./ConversationItem";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import Button from "./Button";
import useDisclosure from "../hooks/useDisclosure";

export default function Conversation() {
  const { messages } = useMessages();
  const messagesBoxRef = useRef(null);
  const navigate = useNavigate();
  const { isOpen, open, toggle } = useDisclosure();

  const scrollToBottom = () => {
    if (messagesBoxRef.current) {
      messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
    }
  };
  
  const endCall = () => {
    toggle()
    navigate(`/`);
  };

  useEffect(() => {
    scrollToBottom();
    if(messages.type === 'redirect_to_lobyy' && messages.text === true) open();
  }, [messages]);

  return (
    <Fragment>
      <div className="text-sm">Conversation record</div>
      <div
        ref={messagesBoxRef}
        className="messages-box overflow-y-auto p-4 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md h-44 mt-2"
      >
        <div className="space-y-2">
          {messages.map((message, index) => (
            <ConversationItem
              key={index}
              type={message.type}
              text={message.text}
            />
          ))}
        </div>
      </div>
      <Dialog open={isOpen} onClose={toggle} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                   Call forwarded
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      The connection to the lift has been taken over by the lobby
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <Button name={'Ok'} onClick={endCall}></Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
