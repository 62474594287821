import { Fragment, useEffect, useState } from "react";
import Button from "./Button";
import { useMessages } from "../context/MessagesContext";
import { useParams } from "react-router-dom";

export default function TypeToPassanger({isEstablished}) {
  const {id} = useParams();
  const { addMessage, joinTopic, leaveTopic, connection, currentTopic } = useMessages();
  const [val, setVal] = useState("");

  useEffect(()=>{
    if(isEstablished && connection && currentTopic !== ''){
      addMessage("operator", 'Communication established');
    }
  },[isEstablished, connection, currentTopic])

  useEffect(()=>{
    if(id){
      joinTopic(id)
      return () => {
        leaveTopic()
      }
    }
  },[id, joinTopic, leaveTopic])

  const sendMessage = () => {
    if(val !== ""){
      addMessage("operator", val);
      setVal("");
    }
  };
  return (
    <Fragment>
      <div>
        <label className="text-sm">Type to passengers for Yes/No responses</label>
        <div className="mt-2">
          <textarea
            value={val}
            onChange={(e) => setVal(e.target.value)}
            name="message"
            rows="4"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          ></textarea>
        </div>
      </div>
      <Button
        name={"Send"}
        className={"w-full mt-4 py-4"}
        onClick={sendMessage}
        disabled={val === ""}
      />
    </Fragment>
  );
}
