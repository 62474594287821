import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ImagePlaceholder from "../assets/icons/image_placeholder.svg";
import Button from "../components/Button";
import Questions from "../components/Questions";
import { SIPContext } from "../context/SIPContext";
import Conversation from "../components/Conversation";
import TypeToPassanger from "../components/TypeToPassanger";
import DialogQuestions from "../components/DialogQuestions";
import useDisclosure from "../hooks/useDisclosure";
import ElevatorInfo from "../components/ElevatorInfo";
import { MessageProvider } from "../context/MessagesContext";
import EndCallButton from "../components/EndCall";
import HoldCall from "../components/HoldCall";

export default function ELevator() {
  const { isOpen, open, toggle } = useDisclosure();
  const { id } = useParams();
  const { makeCallPos, incomingSession, answerCall } = useContext(SIPContext);
  const [isEstablished, setIsEstablished] = useState(false)

  useEffect(()=>{
    const url = new URL(window.location.href);
    if (url.searchParams.has('withRefresh')) { 
      url.searchParams.delete('withRefresh'); 
      window.history.replaceState({}, '', url);
      setIsEstablished(true)
      answerCall()
    }
  },[])

  const call = (n) => {
    console.log(id);
    makeCallPos(`${id}`);
  };
  return (
    <MessageProvider>
      <h4 className="text-xl font-semibold">Emergency Call System</h4>
      <div className="grid grid-cols-2 gap-6 mt-4">
        <div>
          <div className="block lg:hidden">
            <ElevatorInfo id={id} />
          </div>
          <div className="text-sm mt-4 lg:mt-0">Elevator screenshot</div>
          <div className="mt-2">
            {!incomingSession ? (
              <div>
                <img className="w-full" src={ImagePlaceholder} />
              </div>
            ) : (
              <div className="relative overflow-hidden pb-9/16">
                {/* <audio id="remoteAudio" autoplay></audio> */}
                {/* <audio id="localAudio" muted autoplay></audio> */}
                <video
                  id="remoteVideo"
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  style={{ maxWidth: "650px", maxHeight: "400px" }}
                  controls
                ></video>
                <video
                  id="localVideo"
                  style={{ display: "none" }}
                  controls
                  muted
                ></video>
                {/* <audio id="remoteAudio" controls autoPlay></audio>
               <audio id="localAudio" controls muted autoPlay></audio> */}
              </div>
            )}
          </div>
          <div className="hidden lg:block">
            <Questions />
          </div>
          <HoldCall isDesktop={false} />
          <EndCallButton isDesktop={false} />
        </div>
        <div>
          <div className="max-w-lg">
            <div className="hidden lg:block mb-4">
              <ElevatorInfo id={id} />
            </div>
            <Conversation />
            <div className="mt-4">
              <TypeToPassanger isEstablished={isEstablished} />
            </div>
            <Button
              name={"Choose predefined question"}
              variant={"outline"}
              className={"w-full mt-4 py-4 lg:hidden"}
              onClick={open}
            />
            <HoldCall isDesktop={true} />
            <EndCallButton />
          </div>
        </div>
      </div>
      <DialogQuestions open={isOpen} close={toggle} />
      <div style={{ width: "100%", height: "400px", padding: "1rem" }}></div>
      <audio id="remoteAudio" autoPlay playsInline controls></audio>
    </MessageProvider>
  );
}
